<template>
  <div>
    <corp-member :corpId="corpId" />
  </div>
</template>

<script>
import corpMember from '../../frm/corp/member/index.vue'
export default {
  components: {
    corpMember
  },
  data() {
    return {
      corpId:null
    }
  },
  created() {
    this.corpId = this.getCorpId()
  }
}
</script>

<style>
</style>